import React from 'react';
import Authenticated from "../../components/common/Authenticated";
import Layout from "../../components/common/Layout";
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer";
import {Col, Div, Row} from "atomize";
import VideoCard from "../../components/common/VideoCard";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";

const Referrals = ({data}) => {
  const { site } = data
  return (
    <Layout site={site}>
      <Helmet>
        <title>Refer-A-Bud</title>
      </Helmet>
      <WebsiteContainer>
        <Div>
          <Row m={{t: {lg: '5rem', xs: '3rem'}, b: {lg: '10rem'}}}>
            <Col size={{ xs: "12", lg: "4px" }}>
              <Div m={{ b: { xs: "2.5rem", lg: "0" }, t: { lg: '5rem'} }} maxW={{lg: '88%'}}>
                <div id="conjured_referral" />
              </Div>
            </Col>
            <Col size={{ xs: "12", lg: "8" }}>
              <Div
                shadow="1"
                m={{ t: { xs: "2.5rem", lg: "0" }, l: { lg: '6rem' } }}
                border="1px solid"
                borderColor="yellowPink"
                rounded="lg"
              >
                <VideoCard
                  mobileFile={{asset: { url: '/refer_image_mobile.png' }}}
                  file={{asset: { url: '/refer_image.png' }}}
                  icon={{asset: { url: '/disk.svg' }}}
                  title='chilltime'
                  caption='introducing np v4 (final).jpg'
                  mobRatio='1:1'
                />
              </Div>
            </Col>
          </Row>
        </Div>
      </WebsiteContainer>
    </Layout>
  )
}

export default Referrals;

export const query = graphql`
  query Refer {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`
